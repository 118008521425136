<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">

            <div class="marginRight10 marginBottom10">
              <div class="width250">
                <el-input
                  v-model="searchData.code"
                  placeholder="故障编号"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="marginRight10 marginBottom10">
              <div class="width250">
                <el-input
                  v-model="searchData.accessoryName"
                  placeholder="输入车配件名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>

            <div class="marginRight10 marginBottom10">
              <div class="width250">
                <el-input
                  v-model="searchData.handleWay"
                  placeholder="处理方式"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">处理时间：</span>
              <div class="width300">
                <el-date-picker
                  v-model="searchData.handleTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="handleSearch"
                  clearable
                >
                </el-date-picker>
              </div>
            </div>

            <div class="marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                class="marginLeft10"
              >查询</el-button>
            </div>

            <div class="marginRight10 marginBottom10">

              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                class="marginLeft10"
              >重置</el-button>
            </div>

          </div>
        </div>
      </template>

      <template
        slot="menu"
        slot-scope="{ row }"
      >
        <el-button
          v-if="row.handleState	 == 1"
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="deductionMoneyHandle(row)"
        >编辑</el-button>
        <span v-if="row.handleState	 == 2">已提交</span>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      title="编辑"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="accessoryNum">
          <el-input
            v-model="form.accessoryNum"
            placeholder="请输入配件数量"
            onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
            @blur="salaryChange($event,'accessoryNum',999999)"
            type="text"
            clearable
          />
        </template>

        <template slot="handleNum">
          <el-input
            v-model="form.handleNum"
            placeholder="请输入配件数量"
            onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
            @blur="salaryChange($event,'handleNum',999999)"
            type="text"
            clearable
          />
        </template>

        <template slot="menuForm">
          <el-button
            icon="el-icon-close"
            @click="handleClose"
          >取消</el-button>
          <el-button
            @click="submit(form,1)"
            :disabled="commitStateLoading2"
            :loading="commitStateLoading1"
          >保存草稿</el-button>
          <el-button
            @click="submit(form,2)"
            type="primary"
            :disabled="commitStateLoading1"
            :loading="commitStateLoading2"
          >提交</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { list, deductionMoneyHandle } from "@/api/fault/handle";
import dayjs from "dayjs";
export default {
  name: "index",
  data() {
    return {
      commitStateLoading1: false,
      commitStateLoading2: false,
      searchData: {
        code: "",
        accessoryName: "",
        handleTime: "",
        handleWay: "",
      },
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "故障处理列表",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "故障编号",
            prop: "code",
          },
          {
            label: "区域名称",
            prop: "regionName",
          },
          {
            label: "车型名称",
            prop: "carTypeName",
          },
          {
            label: "车辆编号",
            prop: "carCode",
          },
          {
            label: "配件名称",
            prop: "accessoryName",
          },
          {
            label: "配件数量",
            prop: "accessoryNum",
          },
          {
            label: "处理方式",
            prop: "handleWay",
          },
          {
            label: "处理时长(小时)",
            prop: "handleNum",
          },
          {
            label: "发起时间",
            prop: "sendTime",
          },
          {
            label: "处理时间",
            prop: "completeTime",
          },
          {
            label: "处理周期(天)",
            prop: "timeNum",
          },
        ],
      },

      dialogVisible: false,
      form: {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      },
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: "140",
        column: [
          {
            label: "配件名称",
            prop: "accessoryName",
            maxlength: 50,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入配件名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "配件数量",
            prop: "accessoryNum",
            type: "number",
            // value: 1,
            maxRows: 999999.99,
            minRows: 0,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入配件数量",
                trigger: "blur",
              },
            ],
          },
          {
            label: "处理方式",
            prop: "handleWay",
            maxlength: 50,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入处理方式",
                trigger: "blur",
              },
            ],
          },
          {
            label: "处理时长(小时)",
            prop: "handleNum",
            type: "number",
            // value: 1,
            maxRows: 999999.99,
            minRows: 0,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入处理时长",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
      if (
        searchForm.searchData.handleTimeStart &&
        searchForm.searchData.handleTimeEnd
      ) {
        this.searchData.handleTime = [
          searchForm.searchData.handleTimeStart,
          searchForm.searchData.handleTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
    // 扣除押金
    async deductionMoneyHandle(row) {
      // console.log(data)
      this.form = row;
      this.dialogVisible = true;
    },
    onLoad() {
      this.showLoading = true;
      const searchData = {
        code: this.searchData.code,
        accessoryName: this.searchData.accessoryName,
        handleWay: this.searchData.handleWay,
      };
      if (this.searchData.handleTime) {
        searchData.handleTimeStart = dayjs(
          this.searchData.handleTime[0]
        ).format("YYYY-MM-DD 00:00:00");
        searchData.handleTimeEnd = dayjs(this.searchData.handleTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      const searchForm = {
        path: this.$route.path,
        searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));

      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      localStorage.removeItem("searchForm");
      this.searchData = {
        code: "",
        accessoryName: "",
        handleTime: "",
        handleWay: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 提交
    submit(form, commitState) {
      if (
        Number(form.depositPrice) <
        Number(form.parkFeeDeduct) + Number(form.otherDeduct)
      ) {
        this.$message.warning("扣除的押金总和不能大于押金金额");
        return;
      }

      this.$refs.form.validate((valid, done, msg) => {
        if (valid) {
          if (form.id) {
            deductionMoneyHandle({ ...form, commitState })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.onLoad();
                } else {
                  this.$message.error(res.msg);
                  done();
                }
                this.commitStateLoading1 = false;
                this.commitStateLoading1 = false;
              })
              .catch(() => {
                done();
                this.commitStateLoading1 = false;
                this.commitStateLoading1 = false;
              });
          }
        } else {
          this.commitStateLoading1 = false;
          this.commitStateLoading1 = false;

          done();
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        parkFeeDeduct: "",
        otherDeduct: "",
        depositPrice: "",
      };
    },
    salaryChange(e, t, max) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            this.form[t] = max;
            e.target.value = max;
          } else {
            this.form[t] = value * 1;
            e.target.value = value * 1;
          }
        }
      } else {
        this.form[t] = "";
      }
    },
  },
};
</script>

<style scoped>
</style>